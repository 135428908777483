<template>
    <v-container fuild>
        <v-card>
            <v-toolbar flat>
                <v-text-field
                    flat
                    hide-details
                    single-line
                    :label="$t('teams.search')"
                    prepend-inner-icon="mdi-magnify"
                    v-model="search"
                    full-width
                ></v-text-field>
                <v-spacer v-if="profile.security.administration"></v-spacer>
                <v-dialog
                    v-if="profile.security.administration"
                    v-model="dialogCreateTeam"
                    max-width="500px"
                    persistent
                >
                    <template v-slot:activator="{ on }">
                        <v-btn rounded color="primary" dark v-on="on">
                            {{ $t('buttons.createTeam') }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline blue--text text--darken-1">{{ $t('title.newTeam') }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-text-field
                                v-model="referenceCreate"
                                :counter="10"
                                :label="$t('teams.attributes.reference')"
                                required
                                color="red"
                            ></v-text-field>
                            <v-text-field
                                v-model="nameCreate"
                                :counter="50"
                                :label="$t('teams.attributes.name')"
                                required
                                color="red"
                            ></v-text-field>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" text @click="cancelCreate">
                                {{ $t('buttons.cancel') }}
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="create">
                                {{ $t('buttons.save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            <v-list dense rounded>
                <v-list-item v-for="team in teamsToDisplay" :key="team.id" @click="openTeam(team)" class="subtitle-1">
                    <v-list-item-content>
                        <v-list-item-title v-text="team.name" />
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'teams',
    data: () => ({
        dialogCreateTeam: false,
        nameCreate: undefined,
        referenceCreate: undefined,
        search: undefined,
    }),
    created() {
        this.fetchTeams();
    },
    computed: {
        ...mapGetters(['teams', 'profile']),
        teamsToDisplay() {
            const filterTeams = this.teams.filter((t) => {
                if (this.search) {
                    return t.name.toLowerCase().includes(this.search.toLowerCase());
                }
                return true;
            });

            return OrderBy(filterTeams, ['namme'], ['asc']);
        },
    },
    methods: {
        ...mapActions(['findTeams', 'createTeam']),
        cancelCreate() {
            this.dialogCreateTeam = false;
            this.nameCreate = undefined;
            this.referenceCreate = undefined;
        },
        create() {
            const teamToCreate = {
                name: this.nameCreate,
                reference: this.referenceCreate,
            };

            this.createTeam(teamToCreate);
            this.cancelCreate();
        },
        fetchTeams() {
            this.findTeams();
        },
        openTeam(team) {
            this.$router.push({ name: 'team', params: { team_id: team.id } });
        },
    },
};
</script>

<style></style>
